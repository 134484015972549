import { GraphQLClient } from "graphql-request"
import { RequestMiddleware } from "graphql-request/build/esm/types"
import { TRequest, graphqlClientMiddleware } from "shared-utils"

const middleware: RequestMiddleware = (request) => {
  const resultMiddleware = graphqlClientMiddleware({
    request: request as TRequest,
    signOut: () => {
      return
    },
  })
  return resultMiddleware
}

export const federatedGqlClient = new GraphQLClient(
  process.env.NEXT_PUBLIC_FEDERATED_TYK_URL ||
    "https://gtp-tyk.eproc.dev/graphql/",
  {
    requestMiddleware: middleware,
    credentials: "include",
  }
)
