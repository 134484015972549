import type { ThemeUIStyleObject } from "@theme-ui/css"

const notificationListWrapper: ThemeUIStyleObject = {
  "&::-webkit-scrollbar": {
    width: "10px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundClip: "content-box",
    backgroundColor: "#e2e4e8",
    borderRadius: "8px",
    maxHeight: "64px",
    border: "3px solid transparent",
  },
}

const styles = {
  notificationListWrapper,
}

export default styles
